import React, { createElement, lazy } from 'react';
import { Route } from 'react-router-dom';
import Suspended from '~/src/components/global/Suspended';
import NotFound from '~/src/components/pages/NotFound';

// Note: Importing and initializing this shared component is important for bundling the css of all the shared components
// Refer to notes in components/shared/Index.tsx file for more details
import Shared from '~/src/components/shared/Index';
//@ts-ignore
const _ = Shared;

const LazyElement = ({ element, props = null }: { element: React.LazyExoticComponent<any>; props?: any }) => (
	<Suspended>{createElement(element, props)}</Suspended>
);

const Homepage = lazy(() => import('~/src/components/pages/Homepage'));
const Fighters = lazy(() => import('~/src/components/pages/Fighters'));
const Fighter = lazy(() => import('~/src/components/pages/Fighter'));
const Schedule = lazy(() => import('~/src/components/pages/Schedule'));
const Results = lazy(() => import('~/src/components/pages/Results'));
const Event = lazy(() => import('~/src/components/pages/Event'));
const Photos = lazy(() => import('~/src/components/pages/Photos'));
const Tickets = lazy(() => import('~/src/components/pages/Tickets'));
const Tournament = lazy(() => import('~/src/components/pages/Tournament'));
const About = lazy(() => import('~/src/components/pages/About'));
const Watch = lazy(() => import('~/src/components/pages/Watch'));
const Rules = lazy(() => import('~/src/components/pages/Rules'));
const Arena = lazy(() => import('~/src/components/pages/Arena'));
const Faq = lazy(() => import('~/src/components/pages/Faq'));
const Media = lazy(() => import('~/src/components/pages/Media'));
const TicketTerms = lazy(() => import('~/src/components/pages/TicketTerms'));
const Covid19 = lazy(() => import('~/src/components/pages/Covid19'));
const FanCodeOfConduct = lazy(() => import('~/src/components/pages/FanCodeOfConduct'));

export enum RoutePaths {
	Index = '/',
	Tickets = '/tickets',
	Fighters = '/fighters',
	Events = '/events',
	Results = '/results',
	Tournament = '/tournament',
	Photos = '/photos',
	About = '/about',
	Watch = '/watch',
	Media = '/media',
	TicketTerms = '/ticket_terms',
	Covid19 = '/covid_19',
	FanCodeOfConduct = '/fan_code_of_conduct',
	NotFound = '/not_found',
}

export const Routes = (
	<Route>
		<Route path={RoutePaths.Index} element={<LazyElement key="Homepage" element={Homepage} />} />
		<Route path={RoutePaths.Fighters} element={<LazyElement key="Fighters" element={Fighters} />} />
		<Route path={`${RoutePaths.Fighters}/:otx_fighter_id`} element={<LazyElement key="Fighter" element={Fighter} />} />
		<Route path={RoutePaths.Events} element={<LazyElement key="Events" element={Schedule} />} />
		<Route path={`${RoutePaths.Events}/:otx_event_id`} element={<LazyElement key="Event" element={Event} />} />
		<Route path={RoutePaths.Results} element={<LazyElement key="Results" element={Results} />} />
		<Route path={RoutePaths.Photos} element={<LazyElement key="Photos" element={Photos} />} />
		<Route path={RoutePaths.Tickets} element={<LazyElement key="Tickets" element={Tickets} />} />
		<Route path={RoutePaths.Tournament} element={<LazyElement key="Tournament" element={Tournament} />} />
		<Route path={RoutePaths.About} element={<LazyElement key="About" element={About} />} />
		<Route path={RoutePaths.Watch} element={<LazyElement key="Watch" element={Watch} />} />
		<Route path={RoutePaths.Media} element={<LazyElement key="Media" element={Media} />} />
		<Route path={RoutePaths.TicketTerms} element={<LazyElement key="TicketTerms" element={TicketTerms} />} />
		<Route path={RoutePaths.Covid19} element={<LazyElement key="Covid19" element={Covid19} />} />
		<Route
			path={RoutePaths.FanCodeOfConduct}
			element={<LazyElement key="FanCodeOfConduct" element={FanCodeOfConduct} />}
		/>

		<Route path={RoutePaths.NotFound} element={<NotFound key="NotFound" />} />
		<Route path="*" element={<NotFound key="NotFound" />} />
	</Route>
);
