import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
import Urls from '~/src/constants/urls';
import {
	imgIconHeadphonesSvg,
	imgIconLocationPinSvg,
	imgIconStreamingSvg,
	imgPlaceholdersKeyArtWebp,
} from '~/src/images';
import { GetDateString, OtxEvent } from '~/src/models/OtxEvent';
import { FightTypes, GetTournamentRound, OtxFight } from '~/src/models/OtxFight';
import Picture from '~/src/overtime-lib/src/www/components/Picture';
import { RoutePaths } from '~/src/www/routes';
import './EventsList.scss';
import OtxNumber from './OtxNumber';

const _Event = ({ event, tournamentFights }: { event: OtxEvent; tournamentFights: OtxFight[] }) => {
	const mainEvent = event.otx_fights?.find((f) => f.fight_type == FightTypes.MainEvent);
	const isPast = DateTime.fromISO(event.starts_at) < DateTime.now();
	const tournamentFight = event.otx_fights?.find((f) => f.is_tournament_fight);

	return (
		<>
			<div className={`event${!event.starts_at ? ' to-be-announced' : ''}`}>
				{!event.starts_at ? (
					<>
						<div className="image-container">
							<div className="image">
								<Picture src={imgPlaceholdersKeyArtWebp} width={800} />
							</div>
						</div>
						<div className="info-container">
							<div className="info">
								<div className="main-info">
									{event.title ? <div className="title">{event.title}</div> : null}
									<div className="main-event">
										<h2>To be announced</h2>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="image-container">
							{event.starts_at ? (
								<div className="number-container">
									<h3 className={`number ${isPast ? 'number--past' : ''}`}>
										<OtxNumber otxNumber={event.otx_number} />
									</h3>
								</div>
							) : null}
							<div className="image">
								<Picture src={event.image_path ? event.image_path : imgPlaceholdersKeyArtWebp} width={800} />
							</div>
						</div>
						<div className="info-container">
							<div className="info">
								<div className="main-info">
									{event.title ? (
										<div className="title">{GetDateString(new Date(event.starts_at), event.starts_at_timezone)}</div>
									) : null}
									<div className="main-event">
										{mainEvent?.otx_fighter1 && mainEvent?.otx_fighter2 ? (
											<h2>
												{mainEvent.otx_fighter1.last_name} vs. {mainEvent.otx_fighter2.last_name}
											</h2>
										) : (
											<h2>To be announced</h2>
										)}
									</div>
									{tournamentFight ? (
										<div className="tournament-round">
											Tournament: {GetTournamentRound(tournamentFights, tournamentFight)}
										</div>
									) : null}
								</div>
								<div className="highlights">
									<>
										<div className="highlight-icon">
											<img src={imgIconStreamingSvg} />
										</div>
										<div className="highlight-text">DAZN, YouTube, TikTok, Instagram</div>
									</>

									{event.location_name ? (
										<>
											<div className="highlight-icon">
												<img src={imgIconLocationPinSvg} />
											</div>
											<div className="highlight-text highlight-text--uppercase">
												{event.location_name}
												{event.city ? `, ${event.city}` : null}
											</div>
										</>
									) : null}
									{event.dj_name && (
										<>
											<div className="highlight-icon">
												<img src={imgIconHeadphonesSvg} />
											</div>
											<div className="highlight-text highlight-text--uppercase">{event.dj_name}</div>
										</>
									)}
								</div>
								{/* {event.description ? <div className="description desktop-only">{event.description}</div> : null} */}
							</div>
							{/* {event.otx_fights?.length > 0 ? ( */}
							<div className="buttons">
								{isPast ? (
									event.dazn_url ? (
										<a className="cta primary" href={`${event.dazn_url}`}>
											<FontAwesomeIcon icon={faPlay} /> Replay
										</a>
									) : null
								) : event.cta_url && event.cta_text ? (
									<a className="cta primary" href={event.cta_url}>
										{event.cta_text}
									</a>
								) : event.vivenu_id ? (
									<a className="cta primary" href={`${Urls.VivenuEventBase}/${event.vivenu_id}`}>
										Buy Tickets
									</a>
								) : null}
								<a className="cta secondary" href={`${RoutePaths.Events}/${event.id}`}>
									{isPast ? 'Results' : 'Info'}
								</a>
							</div>
							{/* ) : null} */}
						</div>
					</>
				)}
			</div>
		</>
	);
};

const EventsList = ({ events, tournamentFights }: { events: OtxEvent[]; tournamentFights: OtxFight[] }) => {
	if (!events) {
		return (
			<div className="events-list">
				<div className="no-events">Check back later for the announcement of the next event!</div>
			</div>
		);
	}

	return (
		<div className="events-list">
			{events?.length > 0
				? events.map((e) => <_Event event={e} tournamentFights={tournamentFights} key={e.id} />)
				: 'No upcoming events'}
		</div>
	);
};

// Note: There can only be one export per page component for hot loading to work
export default EventsList;
