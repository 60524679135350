import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './PageHeading.scss';

const PageHeading = ({ children, link = null, url = null, isCenter = false }) => (
	<div className={`page-heading ${isCenter ? 'page-heading--center' : ''}`}>
		{isCenter && <div className="heading-lines heading-lines--left"></div>}
		<h3 className="heading">{children}</h3>
		<div className="heading-lines"></div>
		{!!link && (
			<a className="link" href={url}>
				{link}
				<FontAwesomeIcon icon={faArrowRight} />
			</a>
		)}
	</div>
);

export default PageHeading;
