import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ContentContainer from '~/src/components/global/ContentContainer';
import Urls from '~/src/constants/urls';
import { imgOtxBrandmarkShieldPng } from '~/src/images';
import { RoutePaths } from '~/src/www/routes';
import './Navigation.scss';

const Navigation = () => {
	const otxLinks: { text: string; url: string }[] = [];

	const aboutLinks: { text: string; url: string }[] = [
		{ text: 'About OTX', url: RoutePaths.About },
		{ text: 'Fan Code of Conduct', url: RoutePaths.FanCodeOfConduct },
		{ text: 'Ticket Terms', url: RoutePaths.TicketTerms },
		{ text: 'COVID-19', url: RoutePaths.Covid19 },
		{
			text: 'Media & Credentials',
			url: RoutePaths.Media,
		},
		{ text: 'How to Watch', url: RoutePaths.Watch },
	];

	const socialLinks: { name: string; component: JSX.Element; url: string }[] = [
		{
			name: 'YouTube',
			component: <FontAwesomeIcon className="youtube" icon={faYoutube} />,
			url: Urls.SocialYoutube,
		},
		{
			name: 'Instagram',
			component: <FontAwesomeIcon className="instagram" icon={faInstagram} />,
			url: Urls.SocialInstagram,
		},
		{
			name: 'TikTok',
			component: <FontAwesomeIcon className="tiktok" icon={faTiktok} />,
			url: Urls.SocialTikTok,
		},
	];

	return (
		<section className="footer-navigation">
			<ContentContainer>
				<div className="logo-container">
					<a href={RoutePaths.Index}>
						<img src={imgOtxBrandmarkShieldPng} alt="OTX" />
					</a>
				</div>
				<nav className="links">
					{otxLinks.length > 0 && (
						<section className="column">
							<strong className="column-heading">OTX</strong>
							{otxLinks.map((l) => (
								<a href={l.url} key={l.text}>
									{l.text}
								</a>
							))}
						</section>
					)}

					{aboutLinks.length > 0 && (
						<section className="column">
							<strong className="column-heading">About</strong>
							{aboutLinks.map((l) => (
								<a href={l.url} key={l.text}>
									{l.text}
								</a>
							))}
						</section>
					)}

					{socialLinks.length > 0 && (
						<section className="column">
							<strong className="column-heading">Follow</strong>
							<div className="social-icons">
								{socialLinks.map((l) => (
									<a href={l.url} key={l.url} title={l.name}>
										{l.component}
									</a>
								))}
							</div>
						</section>
					)}
				</nav>
			</ContentContainer>
		</section>
	);
};

// Note: There can only be one export per page component for hot loading to work
export default Navigation;
