import React from 'react';
import './Button.scss';

const Button = ({ children, href, ...rest }) => (
	<a className="Button" href={href} {...rest}>
		<div>{children}</div>
	</a>
);

export default Button;
