import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Urls from '~/src/constants/urls';
import {
	imgPlaceholdersFighterFemaleBlueSideWebp,
	imgPlaceholdersFighterFemaleRedSideWebp,
	imgPlaceholdersFighterMaleBlueSideWebp,
	imgPlaceholdersFighterMaleRedSideWebp,
} from '~/src/images';
import { IsLoser, IsOvertime, IsWinner, OtxFight } from '~/src/models/OtxFight';
import { Genders, OtxFighter } from '~/src/models/OtxFighter';
import Picture from '~/src/overtime-lib/src/www/components/Picture';
import { RoutePaths } from '~/src/www/routes';
import './FightCard.scss';
import FontShrinker from './FontShrinker';

const FightCard = ({ fight }: { fight: OtxFight }) => {
	const fighter1 = fight.otx_fighter1;
	const fighter2 = fight.otx_fighter2;

	const getFighterImageUrl = (otx_fighter: OtxFighter, opponent: OtxFighter, color: string = 'red') => {
		if (!otx_fighter && !opponent) {
			return color == 'red' ? imgPlaceholdersFighterMaleRedSideWebp : imgPlaceholdersFighterMaleBlueSideWebp;
		}
		if (!otx_fighter && opponent.gender === Genders.Male) {
			return color == 'red' ? imgPlaceholdersFighterMaleRedSideWebp : imgPlaceholdersFighterMaleBlueSideWebp;
		}
		if (!otx_fighter && opponent.gender === Genders.Female) {
			return color == 'red' ? imgPlaceholdersFighterFemaleRedSideWebp : imgPlaceholdersFighterFemaleBlueSideWebp;
		}
		if (color == 'blue' && otx_fighter.hero_image_2_path) {
			return `${Urls.OvertimeImagesOtx}/${otx_fighter.hero_image_2_path}`;
		}
		if (otx_fighter.hero_image_path) {
			return `${Urls.OvertimeImagesOtx}/${otx_fighter.hero_image_path}`;
		}
		if (otx_fighter.image_path) {
			return `${Urls.OvertimeImagesOtx}/${otx_fighter.image_path}`;
		}
		if (color === 'red' && (otx_fighter.gender === Genders.Male || opponent.gender === Genders.Male)) {
			return imgPlaceholdersFighterMaleRedSideWebp;
		}
		if (color === 'red' && (otx_fighter.gender === Genders.Female || opponent.gender === Genders.Female)) {
			return imgPlaceholdersFighterFemaleRedSideWebp;
		}
		if (color === 'red') {
			return imgPlaceholdersFighterMaleRedSideWebp;
		}
		if (color === 'blue' && (otx_fighter.gender === Genders.Male || opponent.gender === Genders.Male)) {
			return imgPlaceholdersFighterMaleBlueSideWebp;
		}
		if (color === 'blue' && (otx_fighter.gender === Genders.Female || opponent.gender === Genders.Female)) {
			return imgPlaceholdersFighterFemaleBlueSideWebp;
		}
		if (color === 'blue') {
			return imgPlaceholdersFighterMaleBlueSideWebp;
		}

		return imgPlaceholdersFighterMaleBlueSideWebp;
	};

	const mirrorBlueImage =
		getFighterImageUrl(fighter2, fighter1, 'blue').includes(imgPlaceholdersFighterMaleBlueSideWebp) ||
		getFighterImageUrl(fighter2, fighter1, 'blue').includes(imgPlaceholdersFighterFemaleBlueSideWebp);

	return (
		<section className="fight-card">
			<div className="fighters-container">
				<div className="profile profile--left">
					<div className={`profile-image-container profile-image-container--left`}>
						<Picture
							className={`profile-image profile-image--left  ${IsLoser(fight, fighter1) ? 'profile-image--loser' : ''}`}
							src={getFighterImageUrl(fighter1, fighter2, 'red')}
							mobileWidth={285}
						/>
					</div>
					{IsWinner(fight, fighter1) ? (
						<h2 className="winner-tag winner-tag--left">{fight.win_by ? fight.win_by : 'W'}</h2>
					) : (
						<h2 className="loser-tag"></h2>
					)}
				</div>
				<div className="info">
					<div className="name">
						<div className="desktop-only">
							{fighter1 ? (
								<>
									{fighter1?.nick_name ? (
										<FontShrinker className="nick-name">"{fighter1.nick_name}"</FontShrinker>
									) : null}
									{fighter1?.first_name ? (
										<FontShrinker className="first-name">{fighter1.first_name}</FontShrinker>
									) : null}
									{fighter1?.last_name ? <FontShrinker className="last-name">{fighter1.last_name}</FontShrinker> : null}
									{fighter1?.is_active ? (
										<div className="name-link">
											<a href={`${RoutePaths.Fighters}/${fighter1.id}`}>
												View Profile
												<FontAwesomeIcon icon={faArrowRight} />
											</a>
										</div>
									) : null}
								</>
							) : (
								<FontShrinker className="first-name">TBD</FontShrinker>
							)}
						</div>
					</div>
					<div className="fight-info">
						{!fight.otx_winner && <h3 className="vs">vs</h3>}
						{fight.otx_winner && (
							<h3 className="final desktop-only">
								Final
								{IsOvertime(fight) && <div className="overtime">OT</div>}
							</h3>
						)}
						{fight.otx_winner && (
							<h3 className="final mobile-only">
								Final
								{IsOvertime(fight) && ' - OT'}
							</h3>
						)}
						<div className="mobile-only">
							{fight.weight_class && <div className="weight-class">{fight.weight_class}</div>}
							{fight.number_of_rounds && <div className="number-of-rounds">{fight.number_of_rounds} rounds</div>}
						</div>
					</div>
					<div className="name name--right">
						<div className="desktop-only">
							{fighter2 ? (
								<>
									{fighter2?.nick_name ? (
										<FontShrinker className="nick-name">"{fighter2.nick_name}"</FontShrinker>
									) : null}
									{fighter2?.first_name ? (
										<FontShrinker className="first-name">{fighter2.first_name}</FontShrinker>
									) : null}
									{fighter2?.last_name ? <FontShrinker className="last-name">{fighter2.last_name}</FontShrinker> : null}
									{fighter2?.is_active ? (
										<div className="name-link">
											<a href={`${RoutePaths.Fighters}/${fighter2.id}`}>
												View Profile
												<FontAwesomeIcon icon={faArrowRight} />
											</a>
										</div>
									) : null}
								</>
							) : (
								<FontShrinker className="first-name">TBD</FontShrinker>
							)}
						</div>
					</div>
				</div>
				<div className="profile profile--right">
					<div
						className={`profile-image-container profile-image-container--right${mirrorBlueImage ? ' profile-image-container--mirror' : ''}`}
					>
						<Picture
							className={`profile-image profile-image--right ${IsLoser(fight, fighter2) ? 'profile-image--loser' : ''}`}
							src={getFighterImageUrl(fighter2, fighter1, 'blue')}
							mobileWidth={285}
						/>
					</div>
					{IsWinner(fight, fighter2) ? (
						<h2 className="winner-tag winner-tag--right">{fight.win_by ? fight.win_by : 'W'}</h2>
					) : (
						<h2 className="loser-tag"></h2>
					)}
				</div>
			</div>
			<div className="fighter-names-container mobile-only">
				{!fighter1 ? (
					<div className="fighter-name fighter-name--tbd">TBD</div>
				) : fighter1.is_active ? (
					<a className="fighter-name" href={`${RoutePaths.Fighters}/${fighter1.id}`}>
						{fighter1.first_name?.at(0)}. {fighter1.last_name}
					</a>
				) : (
					<div className="fighter-name">
						{fighter1.first_name?.at(0)}. {fighter1.last_name}
					</div>
				)}
				{!fighter2 ? (
					<div className="fighter-name fighter-name--tbd">TBD</div>
				) : fighter2.is_active ? (
					<a className="fighter-name" href={`${RoutePaths.Fighters}/${fighter2.id}`}>
						{fighter2.first_name?.at(0)}. {fighter2.last_name}
					</a>
				) : (
					<div className="fighter-name">
						{fighter2.first_name?.at(0)}. {fighter2.last_name}
					</div>
				)}
			</div>
		</section>
	);
};

export default FightCard;
