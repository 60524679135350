import React from 'react';
import './Footer.scss';
import Legal from './Footer/Legal';
import Navigation from './Footer/Navigation';

const Footer = () => {
	return (
		<footer className="layout-footer">
			<Navigation />
			<Legal />
		</footer>
	);
};

// Note: There can only be one export per page component for hot loading to work
export default Footer;
