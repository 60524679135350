import { isNil } from 'lodash';
import React, { ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './Bracket.scss';

type Game = { id: string };

type Node<G extends Game> = {
	game: G;
	children: Node<G>[];
};

const GameColumn = <G extends Game>({
	column,
	getGameView,
}: {
	column: Node<G>[];
	getGameView: (game: G) => ReactNode;
}) => (
	<>
		{column
			.reduce((acc, g) => (acc[acc.length - 1].length < 2 ? acc[acc.length - 1].push(g) : acc.push([g])) && acc, [[]])
			.map((n) => (
				// We use a random key for empty entries so React rerenders when they're reused
				<div key={n[0].game?.id ?? `${n[0].depth}-${Math.floor(Math.random() * 100)}`}>
					{n[0].depth}
					{getGameView(n[0].game)}
					{n[1] ? getGameView(n[1].game) : null}
				</div>
			))}
	</>
);

const Bracket = <G extends Game, K extends keyof G>({
	games,
	next_game_property,
	getGameView,
	isEditable = false,
	isVertical = false,
	updateActiveTab = null,
}: {
	games: G[];
	next_game_property: K;
	getGameView: (game: G) => ReactNode;
	isEditable?: boolean;
	isVertical?: boolean;
	updateActiveTab?: (s) => void;
}) => {
	const hash = useLocation().hash.slice(1);
	const treeRef = useRef<HTMLDivElement>();

	useEffect(() => {
		const index = ['round1', 'semis', 'championship'].findIndex((r) => r == hash);
		treeRef.current.scroll({ left: index * 220, behavior: 'smooth' });
	}, [hash]);

	const root: Node<G> = { children: [], game: games.find((g) => isNil(g[next_game_property])) };
	const queue = [root];

	while (queue.length > 0) {
		const node = queue.pop();
		if (node.game?.id) {
			node.children = games
				.filter((g) => g[next_game_property] === node.game.id)
				.map((game) => ({ game, children: [] }));

			while (isEditable && node.children.length < 2) {
				node.children.push({ game: { [next_game_property]: node.game.id }, children: [] } as any);
			}
		}
		queue.push(...node.children);
	}

	const leftColumns: Node<G>[][] = [];
	const rightColumns: Node<G>[][] = [];
	const pushChildren = (columns: Node<G>[][], n: Node<G>, depth: number) => {
		columns[depth] = columns[depth] || [];
		columns[depth].push(n);
		n.children?.forEach((n) => pushChildren(columns, n, depth + 1));
	};

	if (root.children.length === 2) {
		pushChildren(leftColumns, root.children[0], 0);
		pushChildren(isVertical ? leftColumns : rightColumns, root.children[1], 0);
	} else if (root.children.length === 1) {
		pushChildren(leftColumns, root.children[0], 0);
	}

	return (
		<div className={`Bracket${isVertical ? ' Vertical' : ' Horizontal'}`}>
			{games.length > 0 || isEditable ? (
				<div className="Tree" ref={treeRef} onScroll={updateActiveTab}>
					{leftColumns.reverse().map((c, i) => (
						<div className="Left" key={i}>
							<GameColumn column={c} getGameView={getGameView} />
						</div>
					))}
					<div>
						<div>{getGameView(root.game)}</div>
					</div>
					{rightColumns.map((c, i) => (
						<div className="Right" key={i}>
							<GameColumn column={c} getGameView={getGameView} />
						</div>
					))}
				</div>
			) : (
				<div className="TBD">TBD</div>
			)}
		</div>
	);
};

export default Bracket;
