import React from 'react';
import './ContentContainer.scss';

const ContentContainer = ({
	children,
	className = null,
	...rest
}: {
	children: React.ReactNode;
	className?: string;
	props?: [string];
}) => (
	<div className={`content-container ${className ?? ''}`} {...rest}>
		{children}
	</div>
);

export default ContentContainer;
