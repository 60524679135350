import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import './Index.scss';

const Layout = ({
	children,
	title = null,
	className = null,
}: {
	children: React.ReactNode;
	title?: string;
	className?: string;
	fullBleed?: boolean;
}) => {
	useEffect(() => {
		document.title = title ? `${title} - OTX` : 'OTX';
	}, [title]);

	return (
		<div className="layout">
			<Header />
			<div className={`${className}`}>{children}</div>
			<Footer />
		</div>
	);
};

export default Layout;
