import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IsLoser, OtxFight } from '~/src/models/OtxFight';
import { OtxFighter } from '~/src/models/OtxFighter';
import FormattedTimestamp from '~/src/overtime-lib/src/www/components/FormattedTimestamp';
import { RoutePaths } from '~/src/www/routes';
import CountryFlag from './CountryFlag';
import './TournamentFightCard.scss';

const _TournamentFighter = ({ fight, fighter }: { fight: OtxFight; fighter: OtxFighter }) => {
	return (
		<div className={`fighter${IsLoser(fight, fighter) ? ' fighter--loser' : ''}`}>
			{fighter ? (
				<>
					<div className="flag">
						<CountryFlag countryCode={fighter.country} />
					</div>
					<div className="name">
						{fighter.first_name} {fighter.last_name}
					</div>
					<h3 className="result">{fight.otx_winner_id === fighter.id ? fight.win_by : ''}</h3>
				</>
			) : (
				<>
					<div className="flag">
						<FontAwesomeIcon icon={faFlag} size="2x" />
					</div>
					<div className="name">TBD</div>
					<h3 className="result"></h3>
				</>
			)}
		</div>
	);
};

const TournamentFightCard = ({ fight }: { fight: OtxFight }) => {
	if (!fight) {
		return <></>;
	}

	return (
		<>
			<div className="tournament-fight-card">
				<div className="header-row">
					<div className="date">
						{fight.otx_event?.otx_number ? (
							<span className="otxNumber">OTX {fight.otx_event?.otx_number}&nbsp;-&nbsp;</span>
						) : null}
						{fight.otx_event?.starts_at ? (
							<>
								<span className="month">
									<FormattedTimestamp timestamp={fight.otx_event.starts_at} format="MMM d" />
								</span>
							</>
						) : (
							<>Coming 2024</>
						)}
					</div>
					{/* {fight.otx_event?.otx_fights?.length > 0 ? ( */}
					{fight.otx_event_id ? (
						<span className="link">
							<a href={`${RoutePaths.Events}/${fight.otx_event_id}`}>View</a>
						</span>
					) : null}
				</div>
				<div className="fighters">
					<_TournamentFighter fight={fight} fighter={fight?.otx_fighter1} />
					<_TournamentFighter fight={fight} fighter={fight?.otx_fighter2} />
				</div>
			</div>
		</>
	);
};

export default TournamentFightCard;
