{
	"COLOR": {
		"BRAND_PRIMARY": "#000",
		"BLACK": "#000",
		"BLACK_80": "#333",
		"WHITE": "#FFF",
		"GRAY_10": "#B6B6B6",
		"GRAY_15": "#A3A3A3",
		"GRAY_20": "#989898",
		"GRAY_30": "#919797",
		"GRAY_40": "#878787",
		"GRAY_50": "#878787",
		"GRAY_80": "#414141",
		"GRAY_90": "#212121",
		"GRAY_95": "#1A1A1A",
		"OTX_BLUE": "#003DF5",
		"BLUE_25": "#9EC1FA",
		"BLUE_45": "#5B84E9"
	},
	"FONT": {
		"BRAND": "not_set",
		"MAIN": "not_set",
		"HIGHLIGHT": "not_set",
		"H1": "not_set",
		"H2": "not_set",
		"H3": "not_set",
		"SMALL": "not_set",
		"TINY": "not_set"
	},
	"SIZE": {
		"GAP": "var(--gap)",
		"GAP_QUARTER": "calc(var(--gap) / 4)",
		"GAP_HALF": "calc(var(--gap) / 2)",
		"GAP_2X": "calc(var(--gap) * 2)",
		"GAP_3X": "calc(var(--gap) * 3)",
		"GAP_4X": "calc(var(--gap) * 4)",
		"GAP_6X": "calc(var(--gap) * 6)",
		"ROW_GAP": "0.55rem",
		"GUTTER": "var(--gutter)",
		"DESKTOP_WIDTH": "1024px"
	},
	"BREAKPOINT": {
		"FULL": "1456px",
		"LARGE": "1200px",
		"MEDIUM": "1024px",
		"SMALL": "768px",
		"EXTRA_SMALL": "375px"
	}
}
