import { OtxFight } from './OtxFight';

export interface OtxFighter {
	id: string;

	is_active: boolean;
	is_otx: boolean;

	last_name?: string;
	first_name?: string;
	nick_name?: string;

	gender?: string;

	birthdate?: string;
	height?: number;
	weight?: number;
	reach?: number;

	weight_class?: string;
	stance?: string;
	trainer?: string;
	hometown?: string;
	country?: string;

	titles: string[];

	biography?: string;

	image_path?: string;
	hero_image_path?: string;
	hero_image_2_path?: string;

	social_tiktok?: string;
	social_instagram?: string;
	social_youtube?: string;
	social_twitter?: string;

	wins?: number;
	loses?: number;
	split_decisions?: number;
	unanimous_decisions?: number;
	tkos?: number;
	kos?: number;

	ranking_ibf?: number;
	ranking_wba?: number;
	ranking_wbc?: number;
	ranking_wbo?: number;

	// TODO: Find a way to push fights 1 and 2 into a single fights object
	otx_fights?: OtxFight[];
	otx_fights1?: OtxFight[];
	otx_fights2?: OtxFight[];
}

export enum Genders {
	Male = 'Male',
	Female = 'Female',
}

export enum WeightClasses {
	Flyweight = 'Flyweight',
	SuperFlyweight = 'Super Flyweight',
	Bantamweight = 'Bantamweight',
	SuperBantamweight = 'Super Bantamweight',
	Featherweight = 'Featherweight',
	SuperFeatherweight = 'Super Featherweight',
	Lightweight = 'Lightweight',
	SuperLightweight = 'Super Lightweight',
	Welterweight = 'Welterweight',
	SuperWelterweight = 'Super Welterweight',
	Middleweight = 'Middleweight',
	SuperMiddleweight = 'Super Middleweight',
	LightHeavyweight = 'Light Heavyweight',
	Heavyweight = 'Heavyweight',
}

export const GetFullName = (otx_fighter: OtxFighter) => {
	if (!otx_fighter) {
		return null;
	}
	return otx_fighter.nick_name
		? `${otx_fighter.first_name} "${otx_fighter.nick_name}" ${otx_fighter.last_name}`
		: `${otx_fighter.first_name} ${otx_fighter.last_name}`;
};

export const GetAge = (fighter: OtxFighter): number => {
	if (!fighter.birthdate) return null;

	const today = new Date();
	const birthdate = new Date(fighter.birthdate);
	var age = today.getFullYear() - birthdate.getFullYear();
	var m = today.getMonth() - birthdate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
		age--;
	}
	return age;
};

export const GetFeetInches = (total_inches?: number): string => {
	if (!total_inches) {
		return null;
	}
	const feet = Math.floor(total_inches / 12);
	const inches = total_inches % 12;
	return `${feet}'${inches}`;
};
