import React, { useEffect, useRef } from 'react';
import './FontShrinker.scss';

const maximumResizeAttempts = 10;

const FontShrinker = ({ className = '', children }: { className?: string; children: React.ReactNode }) => {
	const resizingContainerRef = useRef<HTMLDivElement>();

	useEffect(() => {
		// Use a small timeout because javascript doesn't immediately detect the correct height from extra lines for some reason
		// Hack to try a couple more times because some pages load slower than others
		setTimeout(() => resizeFontToFit(maximumResizeAttempts), 100);
		setTimeout(() => resizeFontToFit(maximumResizeAttempts), 500);
		setTimeout(() => resizeFontToFit(maximumResizeAttempts), 2000);
	}, []);

	const resizeFontToFit = (resizeAttempts: number) => {
		if (resizeAttempts === 0 || !resizingContainerRef.current) return;
		const currentFontSize = parseInt(window.getComputedStyle(resizingContainerRef.current, null).fontSize);
		const divHeight = resizingContainerRef.current.clientHeight;
		if (divHeight >= currentFontSize * 2 && currentFontSize > 8) {
			const resizeFontSize = currentFontSize - 1;
			resizingContainerRef.current.style.fontSize = `${resizeFontSize}px`;
			resizeFontToFit(resizeAttempts - 1);
		}
	};

	return (
		<div className={`font-shrinker ${className}`} ref={resizingContainerRef}>
			{children}
		</div>
	);
};

export default FontShrinker;
