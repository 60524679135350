import Flags from 'country-flag-icons/react/3x2';
import React from 'react';
import './CountryFlag.scss';

const CountryFlag = ({ countryCode }: { countryCode: string }) => {
	const _Flag = Flags[countryCode?.toUpperCase()];
	if (!_Flag) {
		return <></>;
	}

	return (
		<div className="FlagContainer">
			<_Flag />
		</div>
	);
};

export default CountryFlag;
