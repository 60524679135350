import React, { useEffect, useState } from 'react';
import { imgIconLockSvg, imgOtxVipPhotosWebp, imgVipPhotosLockedPng } from '~/src/images';
import { OtxEvent } from '~/src/models/OtxEvent';
import Picture from '~/src/overtime-lib/src/www/components/Picture';
import { RoutePaths } from '~/src/www/routes';
import './Photos.scss';

const Photos = ({ event }: { event?: OtxEvent }) => {
	const localStorageKey = 'hasSignedUp';
	const [hasSignedUp, setHasSignedUp] = useState<boolean>(false);

	// Get signed up status from local storage
	useEffect(() => {
		setHasSignedUp(localStorage.getItem(localStorageKey) === 'true');
	}, []);

	if (!event) {
		return <></>;
	}

	return (
		<section className="event-photos">
			<h2 className="title mobile-only">{hasSignedUp ? 'View VIP Photos' : 'Unlock VIP Photos'}</h2>
			<div className="unlock-lock">
				{hasSignedUp && (
					<div className={`photo-placeholder`}>
						<Picture src={imgOtxVipPhotosWebp} alt="" mobileWidth={550} />
					</div>
				)}
				{!hasSignedUp && (
					<>
						<div className={`photo-placeholder`}>
							<Picture src={imgVipPhotosLockedPng} alt="" mobileWidth={550} />
						</div>
						<div className="lock-icon">
							<img src={imgIconLockSvg} alt="" />
						</div>
					</>
				)}
			</div>
			<div className="cta-container">
				<h2 className="title desktop-only">{hasSignedUp ? 'View Vip Photos Now!' : 'Unlock VIP Photos Now!'}</h2>
				<p className="description">
					Relive the action, see who pulled through and download your pics 📸 tag us @overtimeboxing #OTX
				</p>
				<div className="buttons">
					<a href={`${RoutePaths.Photos}${event ? `?eventId=${event.id}` : ''}`} className="cta primary">
						View
					</a>
				</div>
			</div>
		</section>
	);
};

// Note: There can only be one export per page component for hot loading to work
export default Photos;
