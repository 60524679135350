import React from 'react';
import './Header.scss';
import Navigation from './Header/Navigation';

const Header = () => {
	return (
		<header className="layout-header">
			<Navigation />
		</header>
	);
};

// Note: There can only be one export per page component for hot loading to work
export default Header;
