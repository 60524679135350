import { isNil } from 'lodash';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Html from '~/src/components/global/Html';
import { DocumentProvider } from '~/src/overtime-lib/src/www/contexts/Document';

export default function App({ assets = {}, location = !isNil(window) ? window.location : null, children = null }) {
	return (
		<DocumentProvider location={location}>
			<Html assets={assets}>
				<ErrorBoundary FallbackComponent={Error}>{children}</ErrorBoundary>
			</Html>
		</DocumentProvider>
	);
}

function Error({ error }) {
	return (
		<div>
			<h1>Application Error</h1>
			<pre>{error.stack}</pre>
		</div>
	);
}
