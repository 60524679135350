import React from 'react';
import ContentContainer from '~/src/components/global/ContentContainer';
import './Legal.scss';

const Legal = () => {
	const legalLinks: { text: string; url: string }[] = [
		{ text: 'Terms', url: 'https://overtime.tv/terms' },
		{ text: 'Privacy Policy', url: 'https://overtime.tv/privacy' },
		{ text: 'DMCA', url: 'https://overtime.tv/copyright' },
	];

	return (
		<section className="footer-legal">
			<ContentContainer>
				<div className="footer-legal__copyright">
					All content &copy; {new Date().getFullYear()} Overtime. All rights reserved.
				</div>

				{legalLinks.length > 0 && (
					<div className="footer-legal__links">
						{legalLinks.map((l) => (
							<a href={l.url} key={l.text}>
								{l.text}
							</a>
						))}
					</div>
				)}
			</ContentContainer>
		</section>
	);
};

// Note: There can only be one export per page component for hot loading to work
export default Legal;
