import React from 'react';
import ContentContainer from '~/src/components/global/ContentContainer';
import Layout from '~/src/components/global/Layout/Index';
import { RoutePaths } from '~/src/www/routes';
import './NotFound.scss';

const NotFound = () => {
	return (
		<Layout title="Not Found" className="not-found-page">
			<ContentContainer>
				<h1>404</h1>
				<h2>Oops...</h2>
				<p>The page you're looking for isn't here.</p>
				<a href={RoutePaths.Index}>Go Home</a>
			</ContentContainer>
		</Layout>
	);
};

export default NotFound;
